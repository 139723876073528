<template>
  <div>
    <div class="chart" v-if="chartData.labels.length && chartData.series.length">
    </div>
    <div v-else>
      <EmptyState
        heading="No Data"
        description="You have no assignment in progress"
      />
    </div>
  </div>
</template>

<script>
import Chartist from 'chartist'
const EmptyState = () => import('@/components/app/EmptyState')
export default {
  name: 'LineChart',
  components: {
    EmptyState,
  },
  props: {
    chartData: {
      type: Object,
    },
  },
  mounted() {
    if (this.chartData.labels.length && this.chartData.series.length) {
      Chartist.Line('.chart', {
        labels: this.chartData.labels,
        series: this.chartData.series,
      }, {
        height: 290,
        fullWidth: true,
        chartPadding: {
          right: 40,
        },
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.chart {
  .ct-chart-line {
    margin-left: -50px;
  }
}
</style>
